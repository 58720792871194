import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    AreaPlanBaseModel,
    DevicePlanModel,
    FloorPlanModel
} from '@app/core';
import { SubscriptionNotifier } from '@app/shared';

@Component({
    selector: '[detection-area]',
    templateUrl: 'detectionArea.component.html',
    styleUrls: ['./detectionArea.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'detection-area',
        '[class.selected]': 'device.id===planModel.selectedObject?.id',
        '[class.selected-as-relative]': 'selectedAsRelative'
    }
})
export class DetectionAreaComponent implements OnDestroy, OnInit, AfterViewInit {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    @Input() device: DevicePlanModel;

    private pSelectedAsRelative: boolean;
    public get selectedAsRelative(): boolean {
        return this.pSelectedAsRelative;
    }

    public get detectionAreaPoints(): string {
        return this.device.detectionAreaPoints;
    }

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public planModel: FloorPlanModel
    ) {

    }

    public ngOnInit(): void {
        this.selectAsRelative();
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngAfterViewInit(): void {
        this.planModel
            .floorEvents
            .onDeviceClick
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .floorEvents
            .onAreaClick
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .floorEvents
            .onPlanClick
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .floorPlanDevicesLayerModel
            .onLayerContentUpdated
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .floorPlanAreasLayerModel
            .onLayerContentUpdated
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.changeDetectorRef.detectChanges();
            });
        this.planModel
            .onContentUpdated
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.selectAsRelative();
                this.changeDetectorRef.detectChanges();
            });
        this.changeDetectorRef.detectChanges();
    }

    private selectAsRelative(): void {
        this.pSelectedAsRelative = (this.planModel.selectedObject as AreaPlanBaseModel)?.deviceIDs?.some(t => t.id === this.device.id);
    }


}
